exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-certificates-js": () => import("./../../../src/pages/certificates.js" /* webpackChunkName: "component---src-pages-certificates-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-dealers-js": () => import("./../../../src/pages/dealers.js" /* webpackChunkName: "component---src-pages-dealers-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-engineer-js": () => import("./../../../src/pages/engineer.js" /* webpackChunkName: "component---src-pages-engineer-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-hr-js": () => import("./../../../src/pages/hr.js" /* webpackChunkName: "component---src-pages-hr-js" */),
  "component---src-pages-identification-js": () => import("./../../../src/pages/identification.js" /* webpackChunkName: "component---src-pages-identification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logistics-js": () => import("./../../../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-sales-js": () => import("./../../../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-vacancy-js": () => import("./../../../src/pages/vacancy.js" /* webpackChunkName: "component---src-pages-vacancy-js" */),
  "component---src-templates-news-tempate-js": () => import("./../../../src/templates/newsTempate.js" /* webpackChunkName: "component---src-templates-news-tempate-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/productTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-subcatalog-templates-js": () => import("./../../../src/templates/subcatalogTemplates.js" /* webpackChunkName: "component---src-templates-subcatalog-templates-js" */)
}

